import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

// Define the expected data structure
export interface StoreItem {
  name: string;
  price: number;
  image: string;
  slug: string;
}


export interface User {
  name: string;
  store: StoreItem[];
  url: string;
}

export interface Data {
  data: User[];
}

export type TableItem = StoreItem & { username: string, url: string }


// Define the context type
interface NexusDataContextType {
  data: Data | null;
  loading: boolean;
  error: Error | null;
}

// Create the context
const NexusDataContext = createContext<NexusDataContextType | undefined>(
  undefined,
);

// Create a provider component
export const NexusDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<Data | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://poe-nexus-scraper-api.dwirth987.workers.dev/nexus",
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const body: Data = await response.json();
        setData(body);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <NexusDataContext.Provider value={{ data, loading, error }}>
      {children}
    </NexusDataContext.Provider>
  );
};

// Custom hook to use the NexusDataContext
export const useNexusData = (): NexusDataContextType => {
  const context = useContext(NexusDataContext);
  if (context === undefined) {
    throw new Error("useNexusData must be used within a NexusDataProvider");
  }
  return context;
};

import React from "react";
import { useNexusData } from "./data";
import SortableTable from "./table";
import { Box, Container, Heading, Link, Section, Text } from "@radix-ui/themes";

const App: React.FC = () => {
  const { loading, error } = useNexusData();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Box style={{ background: 'var(--gray-a2)', borderRadius: 'var(--radius-3)' }} p={'2'}>
      <Container size="4">
        <Heading as="h1" size={'6'} >PoE Nexus Creators</Heading>
        <Text as="p">Here is a list of all PoE Nexus Creators that are being <Link href="https://www.pathofexile.com/forum/view-thread/3078195" target="_blank">promoted by GGG. </Link></Text>
        <SortableTable />
      </Container>
      <Section>
        This site is not affiliated with or endorsed by Grinding Gear Games.
      </Section>
    </Box>
  );
};

export default App;

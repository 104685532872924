import React, { useState, useEffect } from 'react';
import { Data, useNexusData } from './data';
import { Box, Button, Flex, Heading, Link, Section, Select, Table } from '@radix-ui/themes';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@radix-ui/react-accordion";
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon } from '@radix-ui/react-icons';

interface UserWithPrice {
    username: string;
    url: string;
    price: number;
    storeURL: string;
}

interface TransformedData {
    armor: string;
    image: string;
    users: UserWithPrice[];
}

const directURL = (landingURL: string, slug: string) => {
    return landingURL.replace('in-game-content', `path-of-exile/${slug}`);
}

const transformData = (data: Data): TransformedData[] => {
    const transformed: { [key: string]: TransformedData } = {};

    data.data.forEach((user) => {
        user.store.forEach((item) => {
            if (!transformed[item.name]) {
                transformed[item.name] = {
                    armor: item.name,
                    image: item.image,
                    users: []
                };
            }
            transformed[item.name].users.push({ username: user.name, url: user.url, storeURL: directURL(user.url, item.slug), price: item.price });
        });
    });

    return Object.values(transformed);
};

const SortableTable: React.FC = () => {
    const { data } = useNexusData();
    const [storeItems, setStoreItems] = useState<TransformedData[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: keyof TransformedData; direction: 'ascending' | 'descending' } | null>(null);
    const [selectedUsername, setSelectedUsername] = useState<string>('All');

    useEffect(() => {
        if (!data) return;
        const allStoreItems = transformData(data);
        setStoreItems(allStoreItems);
    }, [data]);

    const sortItems = (key: keyof TransformedData) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedItems = [...storeItems].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setStoreItems(sortedItems);
        setSortConfig({ key, direction });
    };

    const getArrow = (key: keyof TransformedData) => {
        if (!sortConfig || sortConfig.key !== key) {
            return <></>;
        }
        return sortConfig.direction === 'ascending' ? <ArrowUpIcon /> : <ArrowDownIcon />;
    };

    const handleDropdownChange = (value: React.SetStateAction<string>) => {
        setSelectedUsername(value);
    };

    // Extract unique usernames for dropdown options
    const uniqueUsernames = Array.from(new Set(storeItems.flatMap(item => item.users.map(user => user.username))));

    // Filter items based on the selected username
    const filteredItems = storeItems.map(item => ({
        ...item,
        users: selectedUsername === 'All' ? item.users : item.users.filter(user => user.username === selectedUsername)
    }));

    return (
        <Section size="1">
            <Box pb={'3'}>
                <Flex align={'center'}>
                    <Heading size={'3'}>Filter By Creator: </Heading>
                    <Select.Root defaultValue="All" value={selectedUsername} onValueChange={(value) => handleDropdownChange(value)}>
                        <Select.Trigger variant={'soft'} />
                        <Select.Content>
                            <Select.Group>
                                <Select.Item value="All">All Creators</Select.Item>
                                {uniqueUsernames.map(username => (
                                    <Select.Item key={username} value={username}>{username}</Select.Item>
                                ))}
                            </Select.Group>
                        </Select.Content>
                    </Select.Root>
                </Flex>
            </Box>
            <Table.Root variant='surface' size={'2'} layout={'fixed'}>
                <Table.Header>
                    <Table.Row>
                        <Table.ColumnHeaderCell style={{ cursor: 'pointer' }} maxWidth={'30%'} width={'40ch'} onClick={() => sortItems('armor')}>Name {getArrow('armor')}</Table.ColumnHeaderCell>
                        <Table.ColumnHeaderCell style={{ cursor: 'pointer' }} onClick={() => sortItems('users')}>Number of creators {getArrow('users')}</Table.ColumnHeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredItems.map((item, index) => (
                        item.users.length > 0 &&
                        <Table.Row key={index}>
                            <Table.RowHeaderCell>{item.armor}</Table.RowHeaderCell>
                            <Table.Cell>
                                <Accordion type="single" collapsible>
                                    <AccordionItem value={item.armor}>
                                        <AccordionTrigger asChild={true} className="AccordionTrigger">
                                            <Button variant="surface">
                                                <span>See {item.users.length} Creators</span>
                                                <ChevronDownIcon className="AccordionChevron" aria-hidden />
                                            </Button>
                                        </AccordionTrigger>
                                        <ExpandedUsers item={item} />
                                    </AccordionItem>
                                </Accordion>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table.Root>
        </Section>

    );
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const ExpandedUsers = (props: { item: TransformedData }) => {
    return (
        <AccordionContent>
            <Table.Root>
                <Table.Header>
                    <Table.ColumnHeaderCell>Creator</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>Price</Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>URL</Table.ColumnHeaderCell>
                </Table.Header>
                <Table.Body>
                    {props.item.users.map((user, index) => (
                        <Table.Row key={index}>
                            <Table.RowHeaderCell><Link href={user.url} target='_blank'>{user.username}</Link></Table.RowHeaderCell>
                            <Table.Cell>{formatter.format(user.price)}</Table.Cell>
                            <Table.Cell><Link href={user.storeURL} target='_blank'>{user.storeURL}</Link></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table.Root>
        </AccordionContent>
    )
}

export default SortableTable;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { NexusDataProvider } from "./data";
import App from "./App";
import { Theme, ThemePanel, Text } from "@radix-ui/themes";
import { ThemeProvider } from "./ThemeProvider";
import "@radix-ui/themes/styles.css";
import { KeyboardIcon } from "@radix-ui/react-icons";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="system" storageKey="ui-theme">
      <Theme>
        <NexusDataProvider>
          <App />
        </NexusDataProvider>
        <ThemePanel defaultOpen={false} />
        <Text style={{ position: "absolute", top: '0', right: '0', padding: '5px' }}>Press <KeyboardIcon /> "T" to change the theme </Text>
      </Theme>
    </ThemeProvider>
  </React.StrictMode>,
);
